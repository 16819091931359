/* 
0-600px: phone
600-900px: tablet portrait
900-1200px: talbet landscape
[1200- 1000] normal size
1800px + : big desktop
*/

/* breakpoint arguement choices: 
-phone
-tab-port
-tab-land
-big-desktop
*/
@mixin respond($breakpoint) {
  @if $breakpoint==small-phone {
    @media only screen and (max-width: 25em) {
      @content //400px;;;;;
    }
  }

  @if $breakpoint==small-phone-height {
    @media only screen and (max-height: 900px) {
      @content //400px;;;;;
    }
  }

  @if $breakpoint==phone {
    @media only screen and (max-width: 37.5em) {
      @content //600px;;;;;
    }
  }

  @if $breakpoint==tab-port {
    @media only screen and (max-width: 56.25em) {
      @content //900px;;;;;
    }
  }

  @if $breakpoint==tab-land {
    @media only screen and (max-width: 75em) {
      @content //1200px;;;;;
    }
  }

  @if $breakpoint==big-desktop {
    @media only screen and (min-width: 112.5em) {
      @content //1800px;;;;;
    }
  }
}
