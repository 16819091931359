@import '../../style/colors';

.footer {
  position: fixed;
  bottom: 0;
  height: 150px;
  width: 100%;
  // background: var(--linear-background);
  z-index: 6;
  pointer-events: none;

  &.hideFooter {
    height: 0;
  }

  .darkMode {
    bottom: 60px;
    left: 25px;
    pointer-events: all;
  }

  .toTop {
    bottom: 60px;
    right: 25px;
    pointer-events: all;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem var(--space-sides);
  background-color: #f7f7f9;
  padding-top: 30px;
  width: 100%;

  .copy {
    font-size: 12px;
    color: rgb(170, 170, 170);
  }
}

.logo {
  height: 25px;
  cursor: pointer;
  display: flex;
  height: 100%;

  img {
    height: 25px;
    filter: invert(100%);
  }
}

@media (max-width: 1200px) {
  .footer {
    height: 150px;

    .toTop {
      bottom: 40px;
      right: 25px;
    }
  }
}

@media (max-width: 768px) {
  .footer {
    height: 150px;

    .toTop {
      bottom: 25px;
      right: 25px;
    }

    .darkMode {
      bottom: 25px;
      left: 25px;
    }
  }
}

@media (max-width: 320px) {
  .footer {
    .toTop {
      right: 15px;
    }

    .darkMode {
      left: 15px;
    }
  }
}
