.container {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  row-gap: 0;
  flex-wrap: wrap;

  a {
    color: var(--primary-color);
    font-size: 22px;
    margin-bottom: 5px;
    text-decoration: none;

    &:visited {
      color: var(--primary-color);
    }
  }

  .seeMore {
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
    font-weight: 500;
    min-width: fit-content;
    text-align: left;
    display: inline-block;
    color: var(--primary-color);
    &:hover {
      color: #767676;
    }
  }
}
