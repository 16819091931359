@import '../../style/colors';
@import '../../style/mixins.scss';

.container {
  background-color: var(--background-color);
  min-height: 100vh;
  margin-top: auto;
  box-sizing: border-box;
  display: flex;
  gap: 20px;
  flex: 1;
  padding: 0 var(--space-header);
}

.containerLoading {
  height: 100vh;
}

.buttons {
  display: flex;
  flex: 0 0 fit-content;
  flex-shrink: 0;
  gap: 5px;

  .sortByButton {
    background-color: var(--button-background-color);
    color: var(--primary-color);
    border-color: var(--border-color);
    border-radius: 10px;
    padding: 0.5rem 1rem;
    svg {
      color: var(--primary-color);
    }
    &:hover {
      svg {
        color: var(--primary-text-color);
      }
    }
  }

  .filtersButton {
    display: none;
    background-color: white;
    border-color: #e5e5e5;
    color: #a7a7a7;
    border-radius: 10px;
    padding: 0.5rem 1rem;
  }
}

.sidebar {
  flex: 0 0 200px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  top: 0;
  padding-top: 40px;

  .sidebarHeader {
    height: 90px;
    flex: 0 0 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sidebarTitle {
    font-size: 28px;
    font-weight: 500;
    color: var(--primary-text-color);
    line-height: 110%;
  }

  .filterContainer {
    height: 100%;
  }

  .filterList {
    border: 1px solid var(--border-color);
    background-color: var(--place-holder-color);
    border-radius: var(--border-radius);
    padding: 10px 0;
  }
}

.collapsed {
  flex: 0 0 64px;

  .sidebarHeader {
    padding: 0;
    justify-content: center;
  }

  .iconCollapse {
    transform: rotateZ(180deg);
  }

  .filterList {
    display: none;
  }
}

.iconCollapse {
  cursor: pointer;
  transform: rotateZ(0deg);
  transition: transform ease 300ms;
}

.profileWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.avatar {
  width: 100px !important;
  height: 100px !important;
  border-radius: 50px;
}

.username {
  margin-top: 8px;
  font-size: 24px;
  font-weight: 600;
  color: #333;
}

.address {
  margin-top: 8px;
  font-size: 18px;
  font-weight: 500;
  color: #333;
}

.bio {
  margin-top: 8px;
  font-size: 16px;
  font-weight: 400;
  color: #555;
  text-align: center;
}

.body {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 100%;
  padding-top: 40px;
}

.exploreAll {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  & > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.filterHeader {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  box-sizing: border-box;
  gap: 10px;
  height: 90px;
  @include respond(tab-port) {
    height: auto;
  }
}

@media only screen and (max-width: 960px) {
  .sidebar {
    display: none;
  }
  .buttons {
    .filtersButton {
      display: flex;
    }
  }

  .container {
    flex-direction: column;
  }
}

@media only screen and (max-width: 768px) {
  .body {
    padding-top: 20px;
  }

  .container {
    padding-top: 0px;
  }
}

@media only screen and (max-width: 600px) {
  .container {
    flex-direction: column;

    // /* width */
    // &::-webkit-scrollbar {
    //   width: 0;
    // }
  }

  .collapsed {
    flex-basis: unset;
    flex-grow: 1;

    .filterList {
      display: block;
    }
  }

  .sidebarHeader {
    display: none;
  }

  .exploreAll {
    padding-right: 0;
    overflow-y: visible;
    max-height: none;
  }
}
