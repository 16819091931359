.container {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.tooltip {
  .MuiTooltip-tooltip {
    font-size: 16px;
  }
}

.modal {
  padding: 0px;

  .content {
    min-height: 60px;
    padding-top: 10px;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 5px;
  }

  .options {
    padding: 10px;
  }
}

.title {
  font-weight: 600;
  font-size: 26px;
  color: var(--primary-text-color);

  .subTitle {
    font-size: 14px;
    font-weight: 400;
    max-width: 350px;
    color: var(--secondary-text-color);
  }
}

.gridContainer {
  min-height: 300px;
}

.accumulatedContainer {
  display: flex;
  min-height: 400px;
  padding: 10px;
  overflow: hidden;
}

@media only screen and (max-width: 1200px) {
  .itemsContainer {
    .items {
      grid-row: 2;

      &:nth-child(3),
      &:nth-child(4) {
        grid-row: 3;
      }
    }

    .earning {
      grid-row: 1;
      grid-column: 1 / 3;
    }
  }
}

.communityItem {
  border-top: 1px solid var(--border-color);
  padding-top: 5px;
  width: 100%;

  & > div {
    font-weight: 500;
  }
}

.titleContainer {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--primary-text-color);
  padding-left: 10px;
  height: 100%;
}

.nameContainer {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--primary-text-color);
  font-weight: 600;
  padding-left: 10px;
  height: 100%;
}

.identicon {
  border-radius: 50% !important;
  overflow: hidden;
}

.avatarContainer {
  border-radius: 5px;
  overflow: hidden;
  object-fit: contain;

  .avatar {
    display: flex;
    width: 100%;
    height: 100%;
    max-height: 30px;
    max-width: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .modal {
    padding: 0 !important;
  }
}
