.container {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  user-select: none;

  .divider {
    margin: 0 0 1.5rem 0;
  }

  .title {
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    color: var(--primary-text-color);

    .subTitle {
      font-size: 18px;
      font-weight: 400;
      color: var(--secondary-text-color);
    }
  }

  .mainFormSubtitle {
    font-size: 14px;
    font-weight: 400;
    color: var(--primary-text-color);
    margin-top: 10px;
  }

  .mainForm {
    .mainFormTitle {
      font-size: 22px;
      font-weight: 600;
    }
    .mainFormSubtitle {
      max-width: 350px;
      padding-left: 2rem;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .item {
      border: 1px solid var(--border-color);
      border-radius: 10px;
      padding: 0.5rem 0 0.5rem 2rem;
      color: var(--primary-text-color);

      .subTitle {
        color: var(--secondary-text-color);
        padding-left: 2rem;
        margin-top: -5px;
        margin-bottom: 10px;
      }
    }
  }
}
