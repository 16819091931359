.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  border-radius: 8px;
  padding: 10px 10px;
  margin: 0 -10px 0;
  color: var(--primary-text-color);

  &:hover {
    // background-color: #f8f5fe;
  }

  .firstRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    color: var(--primary-text-color);

    & > div {
      display: flex;
      align-items: center;
    }

    .address {
      font-weight: 600;
      cursor: pointer;
      max-width: 250px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .when {
      color: var(--secondary-text-color);
      font-size: 14px;
    }
  }

  .secondRow {
    color: var(--primary-text-color);
    font-weight: 500;
  }

  .avatarSmall {
    width: 20px !important;
    height: 20px !important;
    border-radius: 50% !important;
    overflow: hidden;
  }

  .avatarBig {
    width: 32px !important;
    height: 32px !important;
    border-radius: 50% !important;
    overflow: hidden;
  }

  .replies {
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 5px;
    padding-left: 10px;
    border-left: 2px solid #ededed;
  }

  @keyframes flash {
    0% {
      background-color: #5b14f7;
      opacity: 0.5;
    }
    100% {
      background-color: transparent;
      opacity: 1;
    }
  }

  &.flash {
    animation: flash 2s ease-in-out;
  }

  @keyframes deleted {
    0% {
      background-color: rgb(255, 60, 60);
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      background-color: transparent;
      opacity: 0;
      height: 0;
    }
  }

  &.deleted {
    animation: deleted 1.5s ease-in;
  }

  &.selected {
    background-color: #ebebeb;
  }
}
