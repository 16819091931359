@import '../../style/colors';
@import 'react-alice-carousel/lib/alice-carousel.css';

.modalPopup {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.modalSliderContainer {
  width: 100%;
  height: calc(100vh - 10rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: none;

  .subModalContainer {
    width: 100%;
    height: calc(100vh - 10rem);
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    .carouselContainer {
      align-items: center;
      display: flex;
      height: calc(100vh - 2rem);
      justify-content: center;
      width: calc(100vw - 10rem);
      cursor: zoom-out;
      @media screen and (max-width: 1200px) {
        width: calc(100vw - 2rem);
        height: calc(100vh - 2rem);
      }
    }
  }

  .modalSliderCounter {
    height: 15px;
    color: white;
  }

  .leftButton {
    left: 10px;
  }

  .rightButton {
    right: 10px;
  }

  .imgStyle {
    display: block;
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    border-radius: 10px;
  }
  .hideButton {
    opacity: 0;
  }
  .sliderButtonWrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 100%;
    z-index: 99;
    svg {
      width: 40px;
      height: 40px;
      color: var(--white-color);
    }
    &:hover {
      transform: scale(1.2);
      transition: all 0.4s ease;
    }
  }

  .closeLabel {
    position: absolute;
    color: white;
    font-weight: 800;
    line-height: 120%;
    top: 20px;
    right: 20px;
    cursor: pointer;
    display: none;
    z-index: 999;
    svg {
      width: 40px;
      height: 40px;
    }
    @media screen and (max-width: 1700px) {
      top: 20px;
    }
    @media screen and (max-width: 1200px) {
      display: block;
    }
  }
}

@media screen and (max-width: 1000px) {
  .sliderButtonWrapper {
    display: none !important;
  }
}
