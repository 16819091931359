div.root {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  background-color: var(--background-color);
  .commentBox {
    border-bottom: 2px solid var(--border-color);
    flex: 1;
    display: flex;
    min-height: 40px;

    & > div.textbox {
      position: relative;
      flex: 1 1 auto;

      .emojis {
        position: absolute;
        margin-top: 5px;
        z-index: 1;
        svg {
          color: var(--primary-text-color);
        }
        &.invisible {
          display: none;
        }
      }
    }

    & > div.buttons {
      display: flex;
      gap: 10px;
      flex-shrink: 0;
      align-items: center;
    }

    .buttons {
      display: flex;
      gap: 10px;

      button {
        max-height: 30px;
      }
    }
  }

  .avatarSmall {
    width: 20px !important;
    height: 20px !important;
    border-radius: 50% !important;
    overflow: hidden;
  }

  .avatarBig {
    width: 32px !important;
    height: 32px !important;
    border-radius: 50% !important;
    overflow: hidden;
  }

  @media only screen and (max-width: 600px) {
    .commentBox {
      flex-direction: column;

      & > div.buttons {
        justify-content: flex-end;
        margin-bottom: 3px;
      }

      .emojis {
        margin-top: 35px !important;
      }
    }
  }
}
