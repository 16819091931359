.title {
  font-size: 16px;
  span {
    color: #939393;
  }
}

.progress {
  margin: 10px 0;
  height: 6px !important;
  border-radius: 5px;
}

.percentage {
  width: 100%;
  text-align: end;
}
