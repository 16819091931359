.container {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  .tooltip {
    .MuiTooltip-tooltip {
      font-size: 16px;
    }
  }

  .new {
    background-color: var(--background-color);

    &:hover {
      background-color: var(--secondary-background-color);

      & button {
        border-color: #d9c8ff;
      }
    }
  }

  .title {
    font-weight: 600;
    font-size: 26px;
    color: var(--primary-text-color);

    .subTitle {
      font-size: 14px;
      font-weight: 400;
      max-width: 350px;
      color: var(--secondary-text-color);
    }
  }

  .gridContainer {
    min-height: 300px;
  }

  .accumulatedContainer {
    display: flex;
    min-height: 400px;
    padding: 10px;
    overflow: hidden;
  }

  @media only screen and (max-width: 1200px) {
    .itemsContainer {
      .items {
        grid-row: 2;

        &:nth-child(3),
        &:nth-child(4) {
          grid-row: 3;
        }
      }

      .earning {
        grid-row: 1;
        grid-column: 1 / 3;
      }
    }
  }

  .nameContainer {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #343434;
    font-weight: 600;
    padding-left: 10px;
    height: 100%;

    .avatarContainer {
      border-radius: 5px;
      overflow: hidden;
      object-fit: contain;

      .avatar {
        display: flex;
        width: 100%;
        height: 100%;
        max-height: 40px;
        max-width: 40px;
      }
    }
  }
}
