// Colors
$violet: var(--primary-color);
$dark: #200344;
$white: var(--white-color);
$red: #ff3434;

$grey-100: #f4f5f8;
$grey-200: #e3e3e3;
$grey-300: #a8a8a8;
$grey-700: #929292;

// Theme colors
$main-page: #fafafa;
$primary: $violet;
$secondary: $dark;
$danger: $red;
$third: $grey-300;
