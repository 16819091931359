.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  border-radius: 8px;
  padding: 10px 10px;
  margin: 0 -10px 0;

  &:hover {
    // background-color: #f8f5fe;
  }

  .firstRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;

    & > div {
      display: flex;
      align-items: center;
    }

    .address {
      font-weight: 600;
      cursor: pointer;
      max-width: 280px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: var(--primary-text-color);
    }

    .when {
      color: var(--secondary-text-color);
      font-size: 14px;
    }
  }

  .secondRow {
    color: var(--primary-text-color);
    font-weight: 500;
  }

  .avatarSmall {
    width: 20px !important;
    height: 20px !important;
    border-radius: 50% !important;
    overflow: hidden;
  }

  .avatarBig {
    width: 32px !important;
    height: 32px !important;
    border-radius: 50% !important;
    overflow: hidden;
  }

  .replies {
    margin-left: 5px;
    margin-bottom: 10px;
    padding-left: 20px;
    border-left: 2px solid var(--border-color);
  }

  .addReplies {
    margin-left: 5px;
    margin-bottom: 20px;
    margin-top: 20px;
    padding-left: 20px;
    border-left: 2px solid var(--border-color);
    background-color: var(--background-color);
  }

  .loadMore {
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    text-decoration: underline;
    color: var(--primary-color);
  }

  @keyframes flash {
    0% {
      background-color: var(--primary-color);
      opacity: 0.5;
    }
    100% {
      background-color: transparent;
      opacity: 1;
    }
  }

  &.flash {
    animation: flash 2s ease-in-out;
  }

  @keyframes deleted {
    0% {
      background-color: rgb(255, 60, 60);
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      background-color: transparent;
      opacity: 0;
      height: 0;
    }
  }

  &.deleted {
    animation: deleted 2s ease-in;
  }

  &.selected {
    background-color: #ebebeb;
  }
}
