@import '../../style/mixins.scss';
.footer {
  width: 100%;
  display: flex;
  bottom: 0;
  background-color: var(--place-holder-color);
  box-shadow: 0 0 30px 0 var(--background-color);
  color: var(--secondary-text-color);
  text-align: center;
  align-self: flex-end;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  z-index: 5;
  .social {
    display: flex;
    align-items: start;
    justify-content: flex-start;

    button {
      svg {
        width: 20px;
        height: 20px;
        color: var(--secondary-text-color);
        fill: var(--secondary-text-color);
      }
    }
  }
  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
    .policy {
      display: flex;
      align-items: center;
      font-size: 14px;
      a {
        color: var(--secondary-text-color);
        text-decoration: none;
        margin-right: 10px;
      }
    }
  }
  @include respond(tab-port) {
    // display: none;
    flex-direction: column;
    align-items: center;
    .right {
      align-items: center;
    }
    &.showFooter {
      display: flex !important;
      position: static;
      padding: 30px 0;
      margin-bottom: 70px;
    }
  }
}

@media only screen and (max-height: 760px) {
  // .footer {
  //   display: none;
  // }
}
