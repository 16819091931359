.container {
  display: flex;
  flex-direction: column;
  padding: 30px 10px;
  .socialList {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    button {
      margin-right: 10px;
    }
    .disableIcon {
      filter: grayscale(1);
    }
    img {
      width: 32px;
      margin-right: 10px;
    }
  }
  .item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    width: 100%;
    cursor: pointer;
    svg {
      margin-right: 20px;
    }
  }
  .item:not(:last-child) {
    border-bottom: 1px solid var(--border-color);
  }
}

.link {
  padding: 10px;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 10px;
  color: var(--primary-text-color);
  width: 100%;
  .text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.modal {
  width: 450px;
}
