.root {
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 45px;

  .title {
    font-size: 18px;
    font-weight: 600;
    display: flex;
    gap: 10px;
    color: var(--primary-text-color);
    .reactions {
      font-weight: 400;
      color: var(--secondary-text-color);

      .count {
        margin-right: 5px;
        font-weight: 500;
      }
    }
  }

  .body {
    margin-top: 15px;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    min-height: 100px;
    padding: 20px;
    width: 100%;
  }

  .mainComment {
    margin-bottom: 35px;
  }

  @media only screen and (max-width: 600px) {
    .body {
      padding: 12px;
    }
  }
}
