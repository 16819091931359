@import '../../style/colors';

.container {
  padding: 40px 0;
}

.subtitle {
  text-align: center;

  color: var(--primary-text-color);
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  text-align: center;
  margin-top: 15px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.walletAddress {
  color: var(--primary-color);
}

.btnGroup {
  display: flex;
  padding: 10px 20px;
  button {
    width: 100%;
  }
}
