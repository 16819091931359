.documentIcon {
  width: 46px;
  height: 46px;
  position: absolute;
  opacity: 1;
  z-index: 5;
  cursor: pointer;
  &:hover {
    opacity: 0;
  }
}
.deleteIcon {
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.registryLabel {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--secondary-text-color);
  padding-right: 2.5em;
  margin-left: 30px;

  p {
    margin-left: 8px;
  }
}
