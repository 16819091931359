.container {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  .title {
    font-weight: 600;
    font-size: 26px;
    color: var(--primary-text-color);
    .subTitle {
      font-size: 14px;
      font-weight: 400;
      max-width: 350px;
      color: var(--secondary-text-color);
    }
  }
  .invite {
    color: var(--primary-text-color);
    .inviteTitle {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    .inviteContainer {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .inviteTitle {
        width: 150px;
        margin-right: 10px;
        font-size: 16px;
        font-weight: bold;
      }
      .inviteAction {
        display: flex;

        .input {
          width: 400px;
          padding: 10px;
          box-sizing: border-box;
          font-size: 16px;
          color: var(--primary-text-color);
          border-radius: var(--border-radius);
          outline: none;
          background-color: var(--secondary-background-color);

          border: 1px solid var(--border-color);
          margin-right: 10px;
        }
        .link {
          padding: 10px;
          background-color: var(--secondary-background-color);
          border: 1px solid var(--border-color);
          border-radius: var(--border-radius);
          color: var(--primary-text-color);
          max-height: 45px;
          svg {
            stroke: var(--primary-text-color);
          }
        }
        .submitButton {
          box-sizing: border-box;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          padding: 5px 10px;
          font-size: 16px;
          letter-spacing: 0.5px;
          user-select: none;
          width: auto;
          &:hover {
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
          }
        }
      }
    }
  }
  .nameContainer {
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--primary-text-color);
    font-weight: 600;
    padding-left: 10px;
    height: 100%;

    .avatarContainer {
      border-radius: 5px;
      overflow: hidden;
      object-fit: contain;

      .avatar {
        display: flex;
        width: 100%;
        height: 100%;
        max-height: 40px;
        max-width: 40px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .invite {
    display: flex;
    flex-direction: column;
    align-items: center;
    .inviteContainer {
      flex-direction: column;
      width: 100% !important;
      .inviteTitle {
        width: auto !important;
        margin-right: 0 !important;
      }
      .input {
        width: 100% !important;
        margin-bottom: 10px;
        margin-right: 0 !important;
      }
      .inviteAction {
        width: 100% !important;

        flex-direction: column;
        .link {
          max-height: fit-content !important;
        }
      }
    }
  }
}
