button.active {
  background-color: var(--primary-color) !important;
  color: var(--white-color) !important;
  border-color: transparent !important;

  path {
    fill: white;
  }
}

.title {
  padding: 16px;
  color: var(--primary-text-color) !important;
  font-size: 16px;
  font-weight: 600;
}
.drawerList {
  color: var(--primary-text-color) !important;
  font-size: 16px;
  font-weight: 600;
  & .selected {
    background-color: var(--secondary-background-color) !important;
  }
}
