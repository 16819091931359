@import 'style/colors';

.inputGroup {
  margin-top: 16px;
  padding-left: 12px;
  padding-right: 12px;

  .inputWrapper {
    margin-top: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.btnGroup {
  width: 100%;
  text-align: start;
  margin-top: 16px;
}

.error,
.successful {
  color: $red;
  font-size: 14px;
  line-height: 24px;
}

.successful {
  color: $violet;
}

.passwordCheckList {
  span {
    flex: none !important;
    color: var(--primary-text-color);
  }
}
