@import '../../style/colors';
@import '../../style/mixins.scss';

.container {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  background-image: url(../../assets/imgs/bg.png);
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100% 800px;
  @include respond(tab-port) {
    background-image: url(../../assets/imgs/bg-small.png);
    background-size: 100% 600px;
    background-position: 10% 10%;
  }
  .searchSection {
    height: 3rem;
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
    margin-right: var(--space-sides);
    margin-left: var(--space-sides);

    @include respond(tab-port) {
      margin-right: 0;
      margin-left: 0;
    }

    & .hidden * {
      display: none;
    }
  }

  hr.divider {
    margin-left: var(--space-sides);
    margin-right: var(--space-sides);
    margin-top: 40px;
    margin-bottom: 30px;
    border-color: var(--border-color);
    @include respond(tab-port) {
      margin: 20px 0;
    }
  }
}

.card {
  flex: 1;
  border-radius: 22px;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

.button {
  width: 118px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.2px;
  box-shadow: none;
  border-radius: 12px;
  box-sizing: border-box;
  cursor: pointer;
  text-decoration: none;
  margin-top: 24px;

  &:hover {
    box-shadow: 0 0 10px rgba(127, 86, 217, 0.5);
  }

  &:not(:last-child) {
    margin-right: 15px;
  }
}

.exploreButton {
  color: $white;
  background-color: $primary;
}

.createButton {
  color: $primary;
  background-color: transparent;
  border: solid 1px $primary;
}

.exploreAllButton {
  width: 180px;

  &:hover {
    box-shadow: 0 0 10px rgba(127, 86, 217, 0.5);
  }

  color: $white;
  background-color: $primary;

  margin-top: 0px;
}

.findOutButton {
  color: $white;
  background-color: $primary;
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin: 0 7%;
  height: 60%;
  align-self: center;
}

.cardMedia {
  position: relative;

  .player {
    top: 0;
    left: 0;
    width: 100% !important;
    height: 580px !important;
  }
}

.cardInfo {
  padding: 6px 30px 24px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cardCategory {
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -0.01em;
  color: #a2a2ad;
}

.cardName {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #fcfcfc;
  margin-top: 4px;
}

.about {
  width: 100%;
  // background: linear-gradient(180deg, var(--white-color) 0%, rgba(234, 234, 241, 0) 100%);
  background-color: rgb(255, 255, 255);
  margin-bottom: 40px;
}

.aboutInner {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  padding-bottom: 80px;
  box-sizing: border-box;
}

.aboutTitle {
  padding-top: 120px;
  font-weight: 700;
  font-size: 40px;
  line-height: 71px;
  letter-spacing: -1.2px;
  color: $secondary;
  text-align: center;
}

.aboutCards {
  padding-top: 80px;
  display: flex;
}

.aboutCard {
  flex: 1;
  margin-right: 22px;
  padding: 33px 20px;
  box-sizing: border-box;
  border-radius: 30px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 20px 80px rgba(18, 18, 35, 0.08);

  &:last-child {
    margin-right: 0;
  }
}

.aboutCardLink {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cardIconWrapper {
  width: 112px;
  height: 112px;
  border-radius: 50%;
  box-shadow: 0 20px 80px rgba(18, 18, 35, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 82px;
    height: 82px;
  }
}

.cardTitle {
  margin-top: 40px;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.25px;
  color: $secondary;
  text-align: center;
}

.cardDesc {
  margin-top: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: $secondary;
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
}

.categories {
  padding-top: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.categoryCard {
  flex: 0 0 calc(33.33% - 26.66px);
  margin-right: 40px;
  margin-bottom: 40px;
  padding: 0 0 24px;
  box-sizing: border-box;
  border-radius: 30px;
  background: var(--white-color);
  box-shadow: 0 20px 80px rgba(18, 18, 35, 0.08);
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  overflow: hidden;

  &:nth-child(3n),
  &:last-child {
    margin-right: 0;
  }

  &:hover {
    box-shadow: 0 0 10px rgba(127, 86, 217, 0.5);
  }
}

.categoryImage {
  width: 100%;
  min-height: 234px;
  background-repeat: no-repeat;
  background-size: 101%;
  background-position-y: 50%;

  .comingSoon {
    width: fit-content;
    padding: 10px 30px;
    color: $white;
    background-color: $primary;
    border-radius: 30px 0;
    font-size: 14px;
    line-height: 16.8px;
    font-weight: 700;
    text-align: center;
  }
}

.cardIconWrapper2 {
  width: 129px;
  height: 129px;
  border-radius: 50%;
  box-shadow: 0px 22.9412px 91.7647px #f2f1fa;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 66px;
    height: 66px;
  }
}

.cardLabelWrapper {
  margin-top: 22px;
  width: 85%;
  height: 64px;
  border-radius: 33px;
  background: #f6f7f9;
  padding: 18px 10px 18px 26px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.extraCard {
    background-color: $primary;

    .cardLabel {
      color: $white;
    }

    .browseBtn {
      background-color: $white;
    }

    .browseBtnIcon {
      color: $primary;
    }
  }
}

.cardLabel {
  font-weight: 700;
  font-size: 20px;
  letter-spacing: -0.31px;
  color: $secondary;
}

.browseBtn {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: $primary;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.browseBtnIcon {
  color: $white;
}

.footer {
  margin-top: 80px;
  width: 100%;
  height: 144px;
  padding: 0 80px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $grey-200;
}

.logo {
  height: 35px;
}

.loggedInTabGroup {
  margin-top: 40px;
}

.tabGroup {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  @include respond(tab-port) {
    justify-content: start;
    gap: 0.25rem;
  }
  @include respond(small-phone) {
    justify-content: center;
  }
  button {
    padding: 10px 20px;
    border-radius: 10px;
    background-color: var(--button-background-color);
    color: var(--primary-color);
    font-weight: bold;
    @include respond(tab-port) {
      padding: 5px 10px;
    }
    &.active {
      background-color: var(--primary-color);
      color: var(--white-color);
    }
  }

  button.sortByButton {
    position: absolute;
    background-color: var(--button-background-color);
    color: var(--primary-color);
    border-color: var(--border-color);
    right: var(--space-sides);
    svg {
      color: var(--primary-color);
    }
    &:hover {
      svg {
        color: var(--primary-text-color);
      }
    }
    @include respond(tab-port) {
      position: absolute;
      right: 0;
    }
    @include respond(small-phone) {
      position: static;
    }
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
}
