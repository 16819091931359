@import '../../style/colors';

.root {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1000;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  padding-right: 5px;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
}

.paper {
  width: calc(100% - 80px);
  max-width: 600px;
  padding: 30px;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: $white;
  outline: none;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  color: #3d3d3d;
  max-height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
}

.title {
  font-weight: 600;
  font-size: 22px;
  margin: 0 0 20px;
}

.formGroup {
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  padding: 15px;

  p {
    margin: 0;
  }

  .formLabel {
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 16px;
    color: #3d3d3d;
    font-weight: 500;
  }

  .formDesc {
    color: rgb(183, 183, 183);
    margin-bottom: 15px;
  }
}

.formInput {
  width: 100%;
  outline: none;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #eaeaf1;
  padding: 8px 16px;
  box-sizing: border-box;
  font-size: 16px;
}

.longInput {
  resize: none;
  height: 100px;
  margin: 0;
}

.lengthIndicator {
  margin-right: 6px;
  text-align: right;
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;

  & + .error {
    margin-top: -20px;
  }
}

.hasError {
  border: 1px solid rgb(235, 87, 87);
}

.error {
  margin: 6px 0 0 6px;
  color: rgb(235, 87, 87);
}

.footer {
  display: flex;
  justify-content: center;
  margin-top: 25px;
  gap: 10px;
  width: 100%;
}

.button {
  width: 168px;
  height: 48px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 18px;
  background-color: $primary;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.save {
  background-color: $primary;
  color: $white;
  flex: 1;
  font-weight: 500;
}

.cancel {
  background-color: $white;
  border: 1px solid #cacaca;
  color: black;
  flex: 1;
  font-weight: 500;
}

.avatarBox {
  position: relative;
  width: 100px;
  height: 100px;
  background-color: #fafafa;
  border-radius: 100px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.avatar {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}

.upload {
  position: absolute;
  width: 34px;
  height: 34px;
  bottom: 4px;
  right: -4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.uploadIcon {
  width: 24px;
  height: 24px;
  color: #cacaca;
}

.disabled {
  cursor: not-allowed;
  box-shadow: none;
  opacity: 0.7;
}

@media only screen and (max-width: 768px) {
  .paper {
    width: calc(100% - 15px);
    max-height: calc(100vh - 180px);
    overflow-y: auto;
  }
}
