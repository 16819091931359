@import 'style/colors';
@import 'style/mixins.scss';

.container {
  position: absolute;
  width: 100%;
  min-height: 100vh;
  top: 0;
  box-sizing: border-box;
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
}

.body {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  background-color: var(--background-color);
  flex: 1;
}

.content {
  margin: 20px 0;
  flex-wrap: nowrap !important;
  @include respond(tab-port) {
    margin: 20px 0 0 0;
  }
}

.photoContainer,
.kycContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--background-color);
  border-radius: 8px;
  color: var(--primary-text-color);
}

.kycContainer {
  border: 1px #f58881 solid;
}
.photoContainer {
  border: 1px var(--border-color) solid;
}

.showWarning {
  border: 1px solid transparent;
  animation: flash 600ms infinite;
  box-shadow: 0 0 5px 1px #ffc0c0;
}

@keyframes flash {
  0%,
  100% {
    border-color: transparent;
  }
  50% {
    border-color: rgb(211, 135, 91);
  }
}

.imageColumn {
  display: flex;
  flex-direction: column !important;
  justify-content: flex-start;
  align-items: center;
  padding: 0 var(--space-sides) 0 0;
  &.previewHidden {
    .photoContainer {
      opacity: 0;
    }
  }
}

.previewSticky {
  position: fixed;
  top: 70px;
  right: 0;
  width: 41.666667%;
  padding-right: var(--space-sides);
  display: none;
  &.showPreviewSticky {
    display: block;
  }
}
.barLoader {
  width: 463px;
}
.fullBarLoader {
  width: 100%;
}

.panel {
  display: flex;
  flex-direction: column !important;
  position: relative;
  box-sizing: border-box !important;
  margin-bottom: 20px !important;
  padding: 0 10px 0 var(--space-sides);
  @include respond(tab-port) {
    padding-right: 0px;
  }
}

.corruptedItems {
  .panel {
    max-height: 250px;
    overflow: auto;
  }
  .corruptedItemtitle {
    font-weight: 600;
    margin-bottom: 20px;
  }

  .corruptedItem {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    div:first-child {
      font-weight: 600;
    }
  }
}

.panelInputs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px;

  .textButton {
    cursor: pointer;
    width: fit-content;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    padding: 2px 5px;
    margin-left: 30px;
    border-color: white;
  }

  .buttonGroup {
    display: flex;
    margin-left: 20px;

    button {
      margin-left: 10px;
    }
  }
}

.select {
  width: 100%;
  outline: none !important;
  height: 50px !important;
  border-radius: 10px !important;
  box-sizing: border-box !important;
  font-size: 16px !important;
  padding-left: 12px !important;
  cursor: pointer !important;
  background-color: var(--place-holder-color);
  border: 1px var(--light-border-color) solid;
  color: var(--primary-text-color) !important;
  .menuLabel {
    margin: 0px !important;
  }

  svg {
    color: var(--primary-text-color) !important;
  }
}

.button {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 112px;
  height: 55px;
  color: var(--primary-text-color);
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: var(--primary-color) !important;
  border-radius: 12px !important;
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 19px;

  &:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  }
}

.formGroup {
  margin-top: 25px;
  position: relative;
  .formLabel {
    margin: 0 0 8px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: var(--primary-text-color);
    svg {
      width: 1rem;
      height: 1rem;
      color: var(--primary-text-color);
    }
  }
  .formInput {
    width: 100%;
    outline: none;
    height: 50px;
    border-radius: 10px;
    border: 0;
    padding: 8px 16px;
    box-sizing: border-box;
    font-size: 16px;
    background-color: var(--place-holder-color);
    input {
      color: var(--primary-text-color);
    }
    border: 1px var(--light-border-color) solid;
    fieldset {
      border-color: transparent !important;
    }
    &::placeholder {
      opacity: 0.66;
    }
    &:focus {
      border-color: var(--primary-color);
    }
  }
  .formResult {
    width: 100%;
    height: 50px;
    padding: 8px 16px;
    box-sizing: border-box;
    font-size: 16px;
    background-color: var(--place-holder-color);
    color: var(--primary-text-color);
    border-radius: 10px;
    outline: none;
    border: 1px solid var(--light-border-color);
    display: flex;
    align-items: center;
    .formIcon {
      display: flex;
      align-items: center;
      margin-right: 10px;
      img {
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }
    }
  }
}

.longInput {
  resize: none;
  height: 100px;
  margin: 0;
}

.lengthIndicator {
  margin-top: 6px;
  margin-right: 6px;
  text-align: right;
  color: rgba(0, 0, 0, 0.6);
}

:global {
  .tui-image-editor {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
}

.errorValidate {
  box-shadow: 0 0 10px 1px rgba(236, 28, 28, 0.76);
}

@media only screen and (max-width: 900px) {
  .panelInputs {
    width: 100%;
    flex-direction: column;
  }
}

.title {
  margin-bottom: 0;

  .titleContent {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 110%;
    color: var(--primary-text-color);
    margin-bottom: 10px;
    margin-top: 0;
  }

  .titleDescription {
    color: var(--secondary-text-color);
    font-size: 16px;
  }
}

.inputGroup {
  box-sizing: border-box !important;
  width: 100%;
  padding: 20px !important;
  background-color: var(--background-color);
  font-size: 16px !important;

  .inputGroupTitle {
    font-weight: 400;
    font-size: 28px !important;
    line-height: 120%;
    color: #121223;
    margin: 0;
    padding: 20px 0;
    border-bottom: 1px #eaecf0 solid;
  }
}

.uploadContainer {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  .uploadTitle {
    display: flex;
    align-items: flex-start;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: var(--primary-text-color);
    svg {
      width: 1rem;
      height: 1rem;
      color: $third;
    }
  }
}

.imageHolder {
  width: 100%;
  background-color: var(--background-color);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;

  .previewTitle {
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 110%;
    margin-top: 5px;
    margin-bottom: 16px;
    color: var(--primary-text-color);
    align-self: flex-start;
  }

  .previewDescription {
    font-size: 20px;
    color: var(--secondary-text-color);
    margin: 0 0 15px;
  }
}

.ad {
  width: 100%;
  height: 100px;
  background-color: var(--secondary-background-color);
  color: var(--secondary-text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  margin: 20px 0;
}
.previewNFT {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: var(--background-color);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 1px #eaecf0 solid;
  z-index: 3;
}

.skeletonContainer {
  width: 100%;
  border-radius: 10px;
  background-image: url(../../assets/imgs/background-color.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  .skeletonContent {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: var(--background-color);
    border-radius: 10px;
    padding: 5px;
  }
}
.documentsList {
  display: flex;
  flex-wrap: wrap;
}

.menuLabel {
  opacity: 0.5;
}

.errorText {
  color: red;
}
.formLabel {
  font-size: 14px;
}

.formInput {
  font-size: 14px;
}

.select {
  font-size: 14px !important;
}

.attributeInput {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  input {
    margin-right: 10px;
    flex: 1;
    color: var(--primary-text-color);
  }
}
