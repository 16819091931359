@import '../../style/colors';

.button {
  width: 170px;
  height: 48px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  background-color: var(--primary-color);
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.save {
  background-color: $primary;
  color: $white;
  margin-right: 40px;
  text-decoration: none;
}

.cancel {
  background-color: $white;
  border: 1px solid $primary;
  color: $primary;
}

.title {
  text-align: center;
  font-weight: 800;
  font-size: 18px;
  line-height: 110%;
  color: var(--primary-text-color);
}

.subtitleContainer {
  text-align: center;
}

.subtitle {
  color: var(--secondary-text-color);
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  opacity: 0.5;
  margin-top: 15px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.walletAddress {
  color: $primary;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 15px;
}
