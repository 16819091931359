@import 'style/colors';

.board {
  width: 100%;
  margin-bottom: 5px;
  height: 100%;
}

.uploadFile {
  display: flex;
  align-items: center;
  cursor: pointer;
  .iconContainer {
    border-radius: 5px;
    display: flex;
    align-items: center;
    background-color: var(--secondary-background-color);
    margin-right: 10px;
    padding: 2px;
    color: var(--primary-text-color);
  }
  .disable {
    svg {
      color: var(--secondary-text-color);
    }
  }
  .iconHasError {
    background-color: $danger;
  }
  .iconText {
    font-size: 16px;
    color: var(--primary-text-color);
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.uploadCont {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  line-height: 24px;
  position: relative;
  padding: 10px;
  margin-left: 20px;
  margin-top: 20px;
}

.labelEmptyCont {
  align-items: flex-start;
}
.emtyCont {
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  width: fit-content;
}

.browse {
  color: #1969ff;
  cursor: pointer;
}

.waitingUpload {
  pointer-events: none;
}

.uploadsubtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: var(--secondary-text-color);
  margin: 8px;
  opacity: 0.5;
}
.icon {
  margin-top: 32px;
  height: 20px;
}

.formatList {
  text-align: center;
  max-width: 250px;
  height: 72px;
}

.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1212233b;
  border-radius: 8px;
}

.additionalImages {
  display: flex;
  flex-wrap: wrap;
  justify-self: start;
  gap: 10px;
  border-radius: 8px;
}

.deleteImage {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  position: relative;

  .radio {
    position: absolute;
    top: 0;
    left: 5px;
    color: white;
  }
  img {
    height: 8rem;
    object-fit: cover;
    border-radius: inherit;
    cursor: unset;
  }
  .deleteIcon {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: red;
    color: #fff;
    z-index: 5;
    opacity: 0;
    border-radius: inherit;
    cursor: pointer;
  }
  .videoIcon {
    width: 8rem;
    height: 8rem;
  }
  .deleteIcon:hover {
    opacity: 0.5;
    cursor: pointer;
  }
  .smallIcon {
    width: 202px;
    height: 126px;
  }
}
.listAssetMobile {
  margin-left: 30px !important;
  .smallIconMobile {
    width: 100px;
  }
}

.smallImage {
  width: 202px;
  height: 126px;
}
.addPhotoButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--secondary-background-color);
  color: var(--secondary-text-color);
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  }

  .iconSubtitle {
    margin: 5px;
    opacity: 0.5;
    font-size: 60px;
    color: var(--secondary-text-color);

    font-weight: 100;
  }
}
.uploadButton {
  margin-bottom: 10px;
  width: 100%;
  height: 3rem;
  color: #fff;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: var(--primary-color);
  border-radius: 12px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

  &:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  }
}

.button {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 112px;
  height: 55px;
  color: #fff;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: var(--primary-color);
  border-radius: 12px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  &:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  }
}
.cancelButton {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 112px;
  height: 55px;
  color: var(--primary-color);
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #fff;
  border: 1px solid var(--primary-color);
  border-radius: 12px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

  &:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  }
}
.popoverContainer {
  background-color: var(--background-color);
}
.popoverLabel {
  height: 100%;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  position: relative;
  padding: 10px;
  margin: 20px;
  color: var(--primary-text-color);
}

.prohibited {
  position: absolute;
  top: 5px;
  right: 5px;
}

.prohibitedMobile {
  display: flex;
  align-items: center;
  margin-right: 5px;

  svg {
    width: 18px;
  }
}

@media only screen and (max-width: 960px) {
  .listItemText {
    font-size: 12px;
  }
  .uploadCont {
    background-color: var(--background-color);
  }
  .formatList {
    margin-bottom: 0;
  }
  .uploadsubtitle {
    font-weight: 300;
    font-size: 12px;
    line-height: 120%;
  }
  .listItemClass {
    max-height: 10rem;
    overflow-y: auto;
    svg {
      color: var(--primary-text-color);
    }
  }
}

.hasError {
  padding: 2px;
  box-shadow: 0 0 10px 1px rgba(236, 28, 28, 0.76);
  overflow: hidden;
}
