.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .label {
    font-size: 16px;
    color: var(--primary-text-color);
    margin-bottom: 5px;
  }
}

.icons {
  color: #eaeaf1;
}
.input {
  width: 100%;
  height: 50px;
  padding: 8px 16px;
  box-sizing: border-box;
  font-size: 16px;
  background-color: var(--place-holder-color);
  color: var(--primary-text-color);
  border-radius: 10px;
  outline: none;
  border: 1px solid var(--light-border-color);
  &::before,
  &::after {
    display: none;
  }
  input {
    color: var(--primary-text-color);
  }
  &:focus {
    border-color: var(--primary-color) !important;
  }
}

.textarea {
  height: 120px;
  resize: vertical;
}

.hasError {
  box-shadow: 0 0 10px 1px rgba(236, 28, 28, 0.76);
}
