@import 'style/colors';

.title {
  font-size: 18px;
  font-weight: bold;
  color: var(--primary-text-color);
}
.description {
  font-size: 16px;
  color: var(--secondary-text-color);
  margin: 10px 0;
}
.qrCodeContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  background-color: var(--secondary-background-color);
  .qrcodeImg {
    width: 100px;
    height: 100px;
  }
}

.verificationTitle {
  margin-top: 10px;
}
.btnGroup {
  display: flex;
  width: 100%;
  gap: 10px;
  button {
    flex: 1;
  }
}

.error {
  input {
    border-color: $red;
  }
}
