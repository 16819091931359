@import '../../style/colors';

.button {
  width: 200px;
  height: 48px;
  border-radius: 8px;
  font-size: 16px;
  background-color: var(--primary-color);
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.save {
  background-color: var(--primary-color);
  color: var(--primary-text-color);
  margin-right: 40px;
  text-decoration: none;
}

.cancel {
  background-color: var(--background-color);
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}

.title {
  text-align: center;
  font-weight: 800;
  font-size: 18px;
  line-height: 110%;
  color: var(--primary-text-color);
}

.subTitle {
  text-align: center;
  font-weight: 800;
  font-size: 14px;
  line-height: 110%;
  color: var(--secondary-text-color);
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 15px;
}

.sumsubContainer {
  width: 100%;
  &.hidden {
    display: none;
  }
}
