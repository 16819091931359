@import '../../style/mixins.scss';

.searchBox {
  width: 100%;

  input {
    height: 90%;
    font-size: 18px;
  }

  .iconButton {
    background-color: var(--light-border-color);
    height: 100px;
    width: 110px;
    display: flex;
    border-radius: 0;

    &:hover {
      background-color: var(--secondary-background-color);
    }
    svg {
      color: #b1b1b1;
      font-size: 2rem;
    }

    @include respond(tab-port) {
      width: 50px;
      svg {
        font-size: 1.5rem;
      }
    }
  }
}
