@import 'style/colors';

.uploadCont {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  line-height: 24px;
  position: relative;
  padding: 10px;
  margin-left: 20px;
  margin-top: 20px;
}

.uploadFile {
  display: flex;
  align-items: center;
  cursor: pointer;
  .iconContainer {
    border-radius: 5px;
    display: flex;
    align-items: center;
    background-color: var(--secondary-background-color);
    color: var(--primary-text-color);

    margin-right: 10px;
    padding: 2px;
  }
  .iconText {
    font-size: 16px;
    color: var(--primary-text-color);
  }
}

.isFocused {
  color: #1969ff;
  border-style: solid;
}

.isDragAccept {
  color: green;
  border-style: solid;
}

.board {
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.waitingUpload {
  pointer-events: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@media only screen and (max-width: 960px) {
  .formInput {
    width: 100%;
  }
  .formNotation {
    font-size: 12px;
    line-height: 15px;
  }
}
