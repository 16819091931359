@import 'style/colors';

.container {
  position: absolute;
  width: 100%;
  min-height: 100vh;
  top: 0;
  box-sizing: border-box;
  background-color: var(--background-color);
  .body {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    background-color: var(--background-color);
  }

  .modal {
    padding: 20px;

    .content {
      min-height: 60px;
      padding-top: 10px;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 5px;
    }

    .options {
      padding: 10px;
    }
  }

  .text {
    color: var(--primary-text-color);
  }
  .content {
    margin: 20px 0;
    flex-wrap: nowrap !important;
  }

  .imageColumn {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start;
    align-items: center;
    padding: 0 var(--space-sides) 0 0;
    &.previewHidden {
      .photoContainer {
        opacity: 0;
      }
    }
  }

  .avatarBig {
    border-radius: 50% !important;
    overflow: hidden;
  }

  .avatarBigIdenticon {
    width: 32px !important;
    height: 32px !important;
    border-radius: 50% !important;
    overflow: hidden;
  }

  .photoContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--background-color);
    border-radius: 8px;
    border: 1px var(--border-color) solid;
    width: 100%;
    min-height: 150px;
  }

  .imageHolder {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    width: 100%;

    .previewTitle {
      width: 100%;
      font-style: normal;
      font-weight: 400;
      font-size: 30px;
      line-height: 110%;
      margin-top: 5px;
      margin-bottom: 16px;
      color: var(--primary-text-color);
    }

    .previewDescription {
      font-size: 20px;
      color: var(--secondary-text-color);
      margin: 0 0 15px;
    }
  }

  .skeletonContainer {
    width: 100%;
    border-radius: 10px;
    background-image: url(../../../assets/imgs/background-color.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 30px;

    .skeletonContent {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      background-color: var(--background-color);
      color: var(--secondary-text-color);
      border-radius: 10px;
      padding: 5px;
      min-height: 120px;

      .smallTitle {
        font-size: 14px;
        color: var(--primary-text-color);
        border-bottom: 1px solid var(--border-color);
        margin-bottom: 5px;
      }
    }
  }

  .ad {
    width: 100%;
    height: 100px;
    background-color: var(--secondary-background-color);
    color: var(--secondary-text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    margin-bottom: 20px;
  }

  .panel {
    display: flex;
    flex-direction: column !important;
    position: relative;
    box-sizing: border-box !important;
    margin-bottom: 20px !important;
    padding: 0 10px 0 var(--space-sides);
  }

  .corruptedItems {
    .panel {
      max-height: 250px;
      overflow: auto;
    }
    .corruptedItemtitle {
      font-weight: 600;
      margin-bottom: 20px;
    }

    .corruptedItem {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      div:first-child {
        font-weight: 600;
      }
    }
  }

  .panelInputs {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .textButton {
      cursor: pointer;
      width: fit-content;
      font-size: 14px;
      font-weight: 500;
      border-radius: 5px;
      padding: 2px 5px;
      margin-left: 30px;
      border-color: white;
    }

    .buttonGroup {
      display: flex;
      margin-left: 20px;

      button {
        margin-left: 10px;
      }
    }
  }

  .select {
    width: 100%;
    outline: none !important;
    height: 50px !important;
    border-radius: 10px !important;
    box-sizing: border-box !important;
    font-size: 16px !important;
    padding-left: 12px !important;
    cursor: pointer !important;
    background-color: var(--white-color);
    border: 1px #eaecf0 solid;
    .menuLabel {
      margin: 0px !important;
    }
  }

  .button {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    width: 112px;
    height: 55px;
    color: #fff;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: var(--primary-color) !important;
    border-radius: 12px !important;
    font-weight: 400 !important;
    font-size: 16px;
    line-height: 19px;

    &:hover {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
    }
  }

  .uploadContainer {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    .uploadTitle {
      display: flex;
      align-items: flex-start;
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;
      color: var(--primary-text-color);
      svg {
        width: 1rem;
        height: 1rem;
        color: $third;
      }
    }
  }

  .errorText {
    color: red;
  }

  .error {
    margin-top: -15px;
    color: red;
  }

  .formGroup {
    margin-top: 25px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .readOnly {
      background-color: var(--secondary-background-color);
    }

    .readOnlyNoBorder {
      border: none;
    }

    .formLabel {
      margin: 0 0 8px;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;
      color: #000000;
      svg {
        width: 1rem;
        height: 1rem;
        color: #eaecf0;
      }
    }
    .formInput {
      width: 100%;
      outline: none;
      height: 50px;
      border-radius: 10px;
      border: 0;
      padding: 8px 16px;
      box-sizing: border-box;
      font-size: 16px;
      background-color: var(--white-color);
      border: 1px #eaecf0 solid;
      fieldset {
        border-color: transparent !important;
      }
      &::placeholder {
        opacity: 0.66;
      }
    }
  }

  .inputGroup {
    box-sizing: border-box !important;
    width: 100%;
    padding: 20px !important;
    font-size: 16px !important;

    .inputGroupTitle {
      font-weight: 400;
      font-size: 28px !important;
      line-height: 120%;
      color: #121223;
      margin: 0;
      padding: 20px 0;
      border-bottom: 1px #eaecf0 solid;
    }
  }

  .title {
    margin-bottom: 0;

    .titleContent {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 110%;
      color: var(--primary-text-color);
      margin-bottom: 10px;
      margin-top: 0;
    }

    .titleDescription {
      color: #cccccc;
      font-size: 16px;
    }
  }
}

@media only screen and (max-width: 900px) {
  .body {
    padding-top: 50px;
  }

  .panelInputs {
    width: 100%;
    flex-direction: column;
  }
}

@media only screen and (max-width: 600px) {
  .modal {
    padding: 0 !important;
  }
}

.previewNFT {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: var(--background-color);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 1px var(--border-color) solid;
  z-index: 9999;
}

.previewSticky {
  position: fixed;
  top: 70px;
  right: 0;
  width: 41.666667%;
  padding-right: var(--space-sides);
  display: none;
  &.showPreviewSticky {
    display: block;
  }
}
