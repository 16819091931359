@import '../../style/colors';

.holder {
  height: 60px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-color);
  cursor: pointer;
  text-decoration: none;

  &:last-child {
    border-bottom: none;
  }
}

.holderInfo {
  display: flex;
  align-items: center;

  .username {
    margin-left: 5px;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    max-width: 200px;
    overflow: hidden;
    color: var(--primary-text-color);
  }
}

.avatarWrapper {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;

  img {
    width: 40px;
    height: 40px;
  }
}

.avatarImg {
  width: 100%;
  height: 100%;
}

.avatar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.info {
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.alias {
  font-weight: 500;
  font-size: 18px;
  color: var(--secondary-text-color);
}

.address {
  margin-top: 4px;
  font-weight: 400;
  font-size: 16px;
  color: var(--primary-text-color);
}

.followers {
  width: 100px;

  button {
    width: 100%;
  }
}
