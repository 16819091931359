@import '../../style/colors';
@import '../../style/mixins.scss';

$scaleFactor: 1.5;
$authAnimationLength: 0.5s;

.outerContainer {
  display: flex;
  flex-direction: column;
  background-image: url(../../assets/imgs/bg.png);
  background-color: var(--background-color);
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100% 100vh;
  height: 100vh;
}

.authContainer {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;

  overflow: hidden;
  .inner {
    box-sizing: border-box;
    background-color: var(--background-color);
    border-radius: 20px;
    height: 100%;
    width: 60%;
    display: flex;
    align-items: center;
    position: relative;
    .header {
      position: absolute;
      padding: 20px;
      top: 0;
      @include respond(tab-port) {
        padding: 10px;
      }
      img {
        width: 50px;
        height: 50px;
        @include respond(small-phone) {
          width: 30px;
          height: 30px;
        }
      }
    }
    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    .authForm {
      width: 80%;
      animation: slideFromBottom $authAnimationLength forwards;
      -webkit-animation: slideFromBottom $authAnimationLength forwards;

      @keyframes slideFromBottom {
        from {
          transform: translate(0, 250px) scale(0.9, $scaleFactor);

          opacity: 0;
        }

        to {
          transform: translate(0, 0) scale(1, 1);

          opacity: 1;
        }
      }

      .title {
        font-size: 32px;
        font-weight: bold;
        line-height: 110%;
        color: var(--primary-text-color);
        padding-bottom: 48px;
        animation: slide $authAnimationLength forwards;
        -webkit-animation: slide $authAnimationLength forwards;

        @keyframes slide {
          from {
            transform: translate(0, 60px) scale(1, 0.666 /*1/$scaleFactor*/);

            opacity: 0;
          }

          to {
            transform: translate(0, 24px) scale(1, 1);

            opacity: 1;
          }
        }
      }
      .titleCenter {
        text-align: center;
      }
      .subTitle {
        font-style: normal;
        font-size: 16px;
        line-height: 120%;
        color: var(--secondary-text-color);
        margin-bottom: 20px;
      }
      .subTitleCenter {
        text-align: center;
      }
      .codeInputContainer {
        display: flex;
        justify-content: center;
      }

      .inputGroup {
        margin-top: 16px;
        .inputWrapper {
          margin-top: 16px;
          text-align: center;
          .input {
            width: 400px;
            height: 60px;
            padding: 8px 16px;
            box-sizing: border-box;
            font-size: 20px;
            color: $secondary;
            border-radius: 12px;
            border: none;
            outline: none;
            border: 0;
            background-color: $grey-100;
            input {
              text-align: center !important;
              background-color: $grey-100;
              color: $secondary;
              height: 21px;
              &:-webkit-autofill {
                box-shadow: 0 0 0 30px $grey-100 inset !important;
                -webkit-box-shadow: 0 0 0 30px $grey-100 inset !important;
                font-size: inherit;
                -webkit-text-fill-color: inherit !important;
              }
            }

            &.password input {
              padding-left: 48px;
            }

            fieldset {
              border: 0;
              border-radius: 12px;
            }
            &.hasError {
              border: 1px solid $red;
            }
          }
        }
      }
      .error {
        color: $red;
        font-size: 14px;
        line-height: 24px;
        text-align: left;
      }
      .formLabelRow {
        padding-top: 16px;
        padding-bottom: 15px;
        display: flex;
        line-height: 32px;
      }
      .formLabelRowCenterredGray {
        margin-top: 20px;
        color: var(--secondary-text-color);
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
      }

      .indented {
        padding-left: 80px;
        padding-right: 80px;
      }
      .forgotPasswordLink {
        color: $primary;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.2px;
        font-weight: 600;
        cursor: pointer;
        padding-top: 12px;
      }
      .labelLink {
        color: var(--primary-text-color);
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: 700;
        margin-left: 5px;
        cursor: pointer;
      }
      .label {
        color: var(--primary-text-color);
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: normal;
        opacity: 0.4;
      }
      .cancelLink {
        margin-top: 16px;
      }
      .buttonsWrapper {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .submitButton {
        width: 100%;
        height: 55px;
        border-radius: 12px;
        font-weight: 700;
        font-size: 16px;
        cursor: pointer;
        display: flex;
        letter-spacing: -0.2px;
        align-items: center;
        justify-content: center;

        &:hover {
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
        }
      }
      .confirmIcon {
        text-align: center;
      }
    }
  }
}

.formGroup {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.formLabel {
  font-weight: 400;
  font-size: 18px;
  letter-spacing: -0.2px;
  color: $secondary;
  margin-bottom: 11px;
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.formInputCont {
  height: 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #eaeaf1;

  &.focused {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  }
}

.formInput {
  width: 100%;
  height: 50px;
  padding: 8px 16px;
  box-sizing: border-box;
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 16px;
}

.termsOfServiceLink {
  text-decoration: none;
}

.passwordCheckList {
  margin-top: 20px !important;
  display: block;
  svg {
    width: 10px;
  }
  span {
    color: var(--primary-text-color);
    text-align: left;
    word-break: break-all;
  }

  @include respond(small-phone-height) {
    display: none;
  }
}

@media only screen and (max-height: 760px) {
  .footer {
    display: none;
  }
}

@media only screen and (max-width: 960px) {
  .authContainer {
    align-items: center;
    .inner {
      width: 100%;

      .authForm {
        width: 90%;
        max-width: 450px;
        height: 100%;
        padding: 10px;
        padding-bottom: 30px;
        border: var(--light-border-color) 1px solid;
        border-radius: 10px;
        @include respond(small-phone-height) {
          padding-bottom: 0;
        }
        .title {
          font-size: 30px;
          font-weight: 600;
          @include respond(small-phone-height) {
            font-size: 20px;
            margin-bottom: 30px;
            line-height: 0;
          }
        }
        .subTitle {
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          @include respond(small-phone-height) {
            display: none;
          }
        }

        .inputGroup {
          .inputWrapper {
            .input {
              width: 100%;
            }
          }
        }
        .formLabelRowCenterredGray {
          margin-top: 20px;

          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }

  .footer {
    display: none;
  }
}

@media only screen and (max-width: 481) {
  .inner {
    width: 100%;
  }
}

@media only screen and (max-height: 760px) {
  .footer {
    display: none;
  }
}
