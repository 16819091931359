@import '../../style/mixins.scss';
.masonryContainer {
  display: flex;
  flex-direction: column;
  min-width: 90px;
  position: relative;
  @include respond(tab-port) {
    margin-bottom: 10px;
  }
  &:hover {
    .media {
      z-index: 0 !important;
      opacity: 1 !important;
    }
  }

  &.videoHasError {
    .mainSection {
      .media {
        z-index: 0;
        height: 0 !important;
        line-height: 0;
        video {
          height: 100% !important;
        }
      }
    }
  }

  .chip {
    border-radius: 3px;
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    background-color: var(--secondary-background-color);
    color: var(--primary-text-color);
    z-index: 4;
  }

  .mainSection {
    position: relative;
    overflow: hidden;
    color: var(--primary-text-color);
    border-radius: 8px;
    &:hover {
      .videoIcon {
        opacity: 0;
      }
    }

    .topRight {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 5;
      svg {
        cursor: pointer;
      }
      .favoriteSection {
        svg {
          scale: 1.3;
        }
      }
    }

    .media {
      // position: inherit;
      border-radius: 8px;
      // overflow: hidden;
      height: 100%;
      z-index: -1;
      opacity: 1;

      &.withThumbnail {
        opacity: 0;

        video {
          cursor: pointer;
          position: absolute;
          top: 0;
          width: 100%;
          height: fit-content !important;
          border-radius: 8px;
          transition: 0.5s ease;
          background-color: #cacaca;
        }
      }
    }

    .videoIcon {
      cursor: pointer;
      position: absolute;
      display: flex;
      flex-direction: column;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      // background-color: #b18eff;
      background-color: #3c3c3cb3;
      padding: 11px;
      opacity: 1;
      transition: opacity 200ms ease-out;

      &.failed,
      &.pending {
        border-radius: 5px;
        padding: 5px;
        flex-direction: row;
        align-items: flex-end;
      }

      &.failed {
        background-color: #f85151;
      }

      svg {
        color: #f2eef9f0;
        font-size: 20px;
      }
    }

    .imageContainer {
      cursor: pointer;
      display: block;
      position: relative;

      a {
        position: absolute;
        z-index: 2;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
      }

      &.videoContainer {
        line-height: 0;
      }

      &.audioContainer {
        height: 130px;
        border-radius: 8px;
        overflow: hidden;

        &.larger {
          height: 145px;
        }

        .audioSource {
          height: 100%;

          .poster {
            position: absolute;
            left: 0;
            right: 0;
            z-index: 0;
            height: 100%;
            background-size: cover;
            background-position: 50%;
            filter: blur(6px);
          }

          .posterOverlay {
            position: absolute;
            left: 0;
            right: 0;
            border-radius: 8px;
            overflow: hidden;
            z-index: 1;
            height: 100%;
            background: linear-gradient(
              0deg,
              rgb(191 191 191 / 40%),
              rgb(175 175 175 / 60%)
            );
          }
        }
      }
    }

    .image {
      display: flex;
      cursor: pointer;
      width: 100%;
      transition: 0.5s ease;
    }

    @keyframes delayedCollapse {
      0%,
      50% {
        height: auto;
      }
      100% {
        height: 0;
      }
    }

    .stampContainer {
      position: absolute;
      opacity: 0;
      width: 100%;
      height: 100%;
      bottom: 0;
      z-index: 4;
      transition: 200ms ease-out;
      background-image: linear-gradient(
        180deg,
        transparent 37.77%,
        rgba(0, 0, 0, 0.32) 65.93%,
        rgba(0, 0, 0, 0.48) 78.19%,
        rgba(0, 0, 0, 0.64) 87.41%,
        rgba(0, 0, 0, 0.8) 98.87%
      );
      &:hover {
        opacity: 1;

        .stamp {
          opacity: 1;
          height: auto;
          bottom: 0;
        }
      }
      a {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
      }
      .stamp {
        position: absolute;
        opacity: 0;
        bottom: -50%;
        left: 50%;
        width: 100%;

        color: var(--white-color);
        text-shadow: 0px 1px 1px #b8b8b8;
        transform: translate(-50%, 0);
        transition: opacity 200ms ease-out, bottom 200ms ease-out;
        border-radius: 6px;
        overflow: hidden;
        cursor: default;
        z-index: 3;
        padding: 10px 15px;

        .stampMain {
          display: flex;
          justify-content: space-between;
          .stampTitle {
            text-overflow: ellipsis;
            white-space: nowrap; /* keeps the text in a single line */
            overflow: hidden;
            font-weight: bold;
            opacity: 1;
          }
        }
        .stampDescription {
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 12px;
          white-space: nowrap;
        }
        .stampAuthor {
          text-decoration: none;
          color: var(--primary-text-color);
          &:hover {
            color: var(--primary-color);
          }
        }
        .stampAuthor,
        .stampPrice {
          display: flex;

          img,
          .icon {
            width: 20px;
            height: 20px;
            border-radius: 6px !important;
            background-color: var(--primary-text-color);
            margin-right: 5px;
            overflow: hidden;
          }
        }

        .middleDisplay {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
      .bottomDisplay {
        display: flex;
        justify-content: flex-start;
        gap: 5px;

        .shareSection {
          height: 100%;
          cursor: pointer;

          .shareIcon {
            display: flex;
            align-items: center;
            font-size: 20px;
          }
        }

        .likeSection {
          display: flex;
          gap: 3px;
          cursor: pointer;

          .likes {
            font-size: 14px;
            font-weight: 500;
            height: 100%;
          }

          .likeIcon {
            font-size: 16px;
            height: 100%;
          }

          .likes,
          .likeIcon {
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .favoriteSection {
      cursor: pointer;

      .favoriteIcon {
        display: flex;
        align-items: center;
        font-size: 20px;

        &.isFavorite {
          color: #ffd700;
        }
      }
    }
  }

  .descriptionSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
    margin: 5px 0 15px;
    & > div {
      display: flex;

      &:first-child {
        position: relative;
        display: flex;
        align-items: center;
        gap: 5px;
        height: 100%;

        .avatar {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          overflow: hidden;
          cursor: pointer;

          &:hover ~ .followButton {
            z-index: 1;
            opacity: 1;
            transform: translate(10px, 0);
            transition: 0.5s ease;
          }
        }

        .followButton {
          position: absolute;
          left: -10px;
          opacity: 0;
          z-index: -1;
          transition: 0.15s ease;

          &:hover {
            opacity: 1;
            z-index: 1;
            transform: translate(10px, 0);
            transition: 0.25s ease;
          }

          button {
            border-color: lightgray;
            background-color: var(--primary-color);
            color: var(--primary-text-color);
            width: 120px;
          }
        }

        .nameSection {
          display: flex;
          flex-direction: column;
          font-size: 16px;
          line-height: 15px;
          font-weight: bold;
          & div:first-child {
            color: var(--primary-text-color);
            cursor: pointer;
          }

          & div:nth-child(2) {
            color: var(--secondary-text-color);
          }

          .stampTime {
            font-size: 12px;
          }
        }
      }

      &:nth-child(2) {
        position: relative;
        justify-content: flex-end;
        align-items: center;
        gap: 4px;
        height: 100%;
        margin-right: 2px;

        .price {
          display: flex;
          flex-direction: column;
          cursor: pointer;

          div:first-child {
            // color: #c1c1c1;
            font-size: 12px;
            font-weight: 500;
          }

          .stampPrice {
            display: flex;
            color: var(--primary-text-color);

            img,
            .icon {
              background-color: var(--primary-text-color);

              width: 18px;
              height: 18px;
              border-radius: 10px !important;
              margin-right: 5px;
              overflow: hidden;
            }
          }

          &:hover ~ .buyNow {
            z-index: 1;
            opacity: 1;
            transform: translate(-10px, 0);
            transition: 0.5s ease;
          }
        }

        .buyNow {
          position: absolute;
          right: -10px;
          opacity: 0;
          z-index: -1;
          transition: 0.15s ease;

          &:hover {
            opacity: 1;
            z-index: 1;
            transform: translate(-10px, 0);
            transition: 0.25s ease;
          }

          button {
            border-color: lightgray;
            background-color: var(--primary-color);
            color: var(--primary-text-color);
            width: 120px;
          }
        }
      }
    }
  }
}
