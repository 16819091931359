@import '../../style/colors';
@import '../../style/mixins.scss';

.container {
  align-items: center;
  background-color: var(--background-color);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;
  position: relative;
  width: 100%;
  @include respond(tab-port) {
    height: auto;
  }
}

.transakAlert {
  cursor: pointer;
  width: fit-content;
  font-size: 14px !important;
  font-weight: 500 !important;
  border-radius: 5px;
  padding: 2px 0 !important;
  border-color: var(--border-color);
  border: none !important;
}

.transakAlertPurple {
  color: var(--primary-color);
  &:hover {
    text-decoration: underline;
  }
}
.carouselContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 205px);
  max-height: calc(100vh - 205px);
  border-radius: 10px;
  background-color: transparent;
  overflow: hidden;
  position: relative;
}

.media {
  border-radius: 5px;
  overflow: hidden;
}

.hidden {
  display: none !important;
}

.body {
  @include respond(tab-port) {
    height: auto;
    padding-top: 10px;

    flex-direction: column !important;
    flex-flow: column-reverse;
  }
}

.bodyFullScreen {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.details {
  max-width: 500px !important;
  max-height: calc(100vh - 85px);
  display: flex;
  flex-direction: column !important;

  &.fullSize {
    max-height: 100%;
  }

  @include respond(tab-port) {
    max-width: 100% !important;
    width: 100%;
    padding: 0 10px;
  }
}

.thumbs {
  position: absolute;
  z-index: 999;
  bottom: 10px;
  background-color: var(--secondary-background-color);

  border-radius: 10px;
  max-width: 80%;
  overflow: hidden;
  li {
    width: 100px !important;
    vertical-align: middle;
    filter: opacity(50%);
  }
}

.thumbnailContainer {
  display: flex;
  flex: 1 !important;
  gap: 10px;
  max-width: 100% !important;
  height: 100% !important;
  flex-direction: column !important;
  position: relative;
  padding: 10px 20px 0 20px;
  @include respond(tab-port) {
    padding: 0 5px;
  }
}

.ad {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-background-color);
  color: var(--secondary-text-color);
  margin-top: 20px;
  min-height: 100px;
  height: 100px;
  border-radius: 10px;
  @include respond(tab-port) {
    display: none;
  }
}

.title {
  font-size: 30px;
  color: var(--primary-text-color);
  font-weight: 600;
  display: grid;
  grid-template-columns: 1fr 55px;
  gap: 5px;
  max-width: 100%;
  overflow: hidden;

  & > span {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    text-wrap: wrap;
    text-overflow: ellipsis;
    line-height: 1.2;
    width: 100%;
  }

  .actions {
    display: flex;
    align-items: center;
  }
}

.priceContainer {
  .priceTitle {
    font-size: 20px;
    color: var(--secondary-text-color);
    line-height: 50px;
  }
  .price {
    display: flex;
    align-items: center;
    font-size: 20px;
    margin-bottom: 20px;
    img {
      width: 20px;
      margin-right: 5px;
    }
  }
  .priceAction {
    margin-bottom: 20px;
  }
  .nftAction {
    display: flex;
    .nftActionItem {
      display: flex;
      align-items: center;
      margin-right: 10px;
      svg {
        font-size: 14px;
        color: #d9d9d9;
        margin-right: 5px;
      }
    }
  }
}

.itemsList {
  display: flex;
  overflow-x: auto;
  padding-bottom: 20px;
  margin-bottom: -20px;
  .documentContainer {
    position: relative;
    width: 100%;
    overflow-y: auto;
    padding-left: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .documentLink {
    margin: 5px;
  }

  .documentPaddings {
    padding-bottom: 10px;
    color: var(--secondary-text-color);

    .registryLabel {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: var(--secondary-text-color);
      opacity: 0.5;
      padding-right: 2.5em;
    }
    .registryValue {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 120%;
      padding-bottom: 10px;
      opacity: 1;
      color: var(--primary-text-color);
    }
    .tagValue {
      display: flex;
      flex-wrap: wrap;
    }

    .tagItem {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      background-color: var(--primary-color);
      padding: 6px 8px;
      margin-right: 5px;

      font-size: 16px;
      font-weight: 700;
      line-height: 120%;
      color: white;
      text-decoration: none;
    }
  }
}

.status {
  width: 100%;
  margin: 20px 0;

  .statusMessage,
  .statusTitle {
    color: var(--primary-text-color);
  }

  .statusTitle {
    font-size: 16px;
    font-weight: 500;
    span {
      color: var(--secondary-text-color);
    }
  }
  .statusMessage {
    color: $primary;
    font-weight: 600;
    margin-bottom: 10px;
  }
}

.itemStats {
  margin: 10px 0;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--secondary-text-color);
}

.itemOwner {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--secondary-text-color);
}

.ownerAvatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;

  .avatar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.ownerName {
  margin-right: 5px;
}
.ownerName,
.owner a,
.from a,
.to a {
  text-decoration: none;
  color: $primary;
}

.panelBody {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.panelBodyCategory {
  display: flex;
}

.panelLine {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 14px;

  &:last-child {
    margin-bottom: 0;
  }
}

.link {
  color: var(--primary-color);
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
}

.scrollContainer {
  display: flex;
  align-items: stretch;
  flex-wrap: nowrap;
  flex-direction: row;
  position: relative;
  .scrollContent {
    width: 100%;
    &.fullSize {
      overflow-y: unset;
    }
    @include respond(tab-port) {
      overflow: hidden;
      width: 100%;
    }
  }
}

.commentsMobile {
  padding: 5px;
}

.currentPriceContainer {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  .currentPriceTitle {
    font-size: 16px;
    color: var(--secondary-text-color);
    margin-bottom: 5px;
  }
  .currentPriceValue {
    display: flex;
    align-items: center;
    img {
      width: 20px;
      height: 20px;
      margin-right: 5px;
      border-radius: 50%;
    }
    .currentPrice {
      font-size: 16px;
      color: var(--primary-text-color);
    }
  }
}

.offerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 10px 0;
  .name,
  .offer {
    flex: 1;
  }

  .offer {
    .offerTitle {
      color: #bdbbbf;
    }
    .offerPrice {
      font-size: 16px;
      font-weight: 600;
    }
  }
}

.tradeContainer {
  margin-bottom: 10px;

  .tradeContent {
    display: flex;
  }
  .date {
    color: #bdbbbf;
    font-style: italic;
  }
}
.name {
  display: flex;
  .nameTitle {
    color: #bdbbbf;
  }
  .nameContent {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    margin: 0 10px 0 5px;
    .avatar {
      display: flex;
    }
    .avatarImg {
      height: 17px;
      width: 17px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 5px;
    }
  }
}

.actionGroup {
  margin: 10px 0;
  display: flex;
  gap: 10px;
  align-items: center;
  @include respond(tab-port) {
    display: flex;
    flex-direction: column;
  }
}

div.buttonsContainer {
  display: flex;
  align-items: center;

  .textButton {
    cursor: pointer;
    width: fit-content;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    padding: 1px 5px;
    border: none !important ;
    color: var(--primary-text-color);
    display: flex;
    gap: 5px;
    svg {
      width: 20px;
      path {
        fill: var(--primary-text-color);
      }
    }
    &.selected {
      background-color: var(--secondary-background-color);
      &:hover {
        background-color: var(--background-color) !important;
      }
    }

    .comments {
      font-weight: 600;
      margin-left: -2px;
    }

    &:hover {
      background-color: var(--secondary-background-color) !important;
    }
  }
}

.actionLoading {
  margin: 10px 10px 0 0;
}

.result {
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  .winner {
    display: flex;
    img {
      margin-left: 11px;
    }
  }
  a {
    text-decoration: none;
  }
}

.descriptionRoot {
  display: flex;
  align-items: center;

  &.full {
    max-width: none !important;
  }

  .bioText {
    margin: 10px 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
    color: var(--primary-text-color) !important;
    &.short {
      text-overflow: ellipsis;
      white-space: wrap;
    }

    &.full {
      white-space: pre-wrap;
      overflow-y: scroll;
      scrollbar-color: var(--background-color) var(--background-color);
    }
  }

  .seeMore {
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
    font-weight: 500;
    min-width: fit-content;
    text-align: left;
    display: inline-block;
    margin-left: 20px;
    color: var(--primary-color);
    &:hover {
      color: #767676;
    }
  }
}
