.carouselContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 205px);
  max-height: calc(100vh - 205px);
  border-radius: 10px;
  background-color: transparent;
  overflow: hidden;
  position: relative;

  &.audio {
    align-items: flex-start;
    height: 440px;

    .carouselContainer {
      height: 440px;
      align-items: flex-start;
    }

    & > div:first-of-type {
      width: 100%;
    }
  }

  .carouselContainer {
    & > div:not(.imgStyleAudio) {
      height: 100%;
      border-radius: 10px;
      overflow: hidden;
    }
  }

  &.fullSize {
    height: calc(100vh - 95px);

    .carouselContainer {
      height: calc(100vh - 95px);
    }
  }

  &:hover {
    .prevIcon {
      opacity: 1;
      left: 10px;
    }
    .nextIcon {
      opacity: 1;
      right: 10px;
    }
    .thumbs {
      bottom: 10px;
      opacity: 1;
    }
    .fullscreen {
      opacity: 1;
    }
  }
  .prevIcon,
  .nextIcon {
    position: absolute;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 10;
    svg {
      color: var(--primary-color);
      font-size: 30px;
    }
  }

  .imgStyle {
    display: block;
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    border-radius: 10px;
    cursor: zoom-in;
  }
  .videoStyle {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    object-fit: contain;
  }
  .fullscreen {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f3f3f3;
    color: var(--primary-color);
    border-radius: 10px;
    padding: 10px;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
    transition: all 0.2s ease;
    cursor: pointer;
    opacity: 0;
    transition: all 0.2s ease;
    svg {
      margin-left: 5px;
    }
    &:hover {
      transform: scale(1.1);
    }
  }
  .prevIcon {
    left: -10px;
    opacity: 0;
    transition: all 0.2s ease;
  }
  .nextIcon {
    right: -10px;
    opacity: 0;
    transition: all 0.2s ease;
  }
  li {
    width: 100% !important;
    vertical-align: middle;
  }
}

.carouselContainerMobile {
  margin-top: 0;
}

.subCarouselContainer {
  max-width: 100px;
  height: 100px;
  margin: 5px;
  cursor: pointer;
  .imgStyle {
    width: 100%; /* or any custom size */
    height: 100%;
    object-fit: cover;
    -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
  }
  .videoStyle {
    display: block;
    width: 100%; /* or any custom size */
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }

  .imgContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
}

.thumbs {
  position: absolute;
  z-index: 9;
  bottom: -30px;
  opacity: 0;
  transition: all 0.2s ease;
  background-color: #817f77;

  border-radius: 10px;
  max-width: 80%;
  overflow: hidden;
  li {
    width: 100px !important;
    vertical-align: middle;
    filter: opacity(50%);
  }
}

.imgStyleAudio {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 900px) {
  .carouselContainer {
    height: 300px;
    max-height: fit-content;
  }
  .videoStyle {
    margin: 0 5px;
  }
}
