.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  .title {
    font-weight: 600;
    font-size: 26px;

    .subTitle {
      font-size: 14px;
      font-weight: 400;
      max-width: 350px;
      color: lightgray;
    }
  }

  .itemsContainer {
    display: grid;
    grid-row: 1;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;

    .items {
      grid-row: 1;
      display: flex;
      flex: 1 1;
      flex-direction: column;
      gap: 10px;
      padding: 20px;
      border: 1px solid var(--border-color);
      border-radius: 10px;
      font-weight: 500;

      & > div:nth-child(2) {
        font-weight: 600;
        font-size: 32px;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .itemsContainer {
      grid-template-columns: repeat(2, 1fr);

      .items {
        grid-row: 2;

        &:nth-child(3),
        &:nth-child(4) {
          grid-row: 3;
        }
      }

      .earning {
        grid-row: 1;
        grid-column: 1 / 3;
      }
    }
  }
}
