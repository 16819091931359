.filter-root {
  .MuiCollapse-container {
    display: flex;
  }

  .MuiCollapse-wrapper {
    width: 100%;
    max-height: 100%;

    div[role='region'] {
      height: 100%;
    }
  }
}
