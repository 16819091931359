.root {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100px;
  min-height: 100px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  // background-color: hsla(0, 50%, 100%, 0.45);
  gap: 5px;
  top: 15px;

  &.larger {
    top: 25px;
  }

  .container {
    display: flex;
    width: 100%;
    height: fit-content;
    z-index: 5;
    padding-right: 10px;

    &:hover .hover {
      opacity: 1;
    }
  }

  .wavesContainer {
    width: 100%;
    height: fit-content;
  }

  .waveform {
    cursor: pointer;
    position: relative;
    height: fit-content;
  }

  .loadingBar {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #e0e0e0;
    transform: translateY(-50%);
  }

  .hover {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 20;
    pointer-events: none;
    height: 100%;
    width: 0;
    background: hsla(0, 0%, 85%, 0.25);
    opacity: 0;
    transition: opacity 0.2s ease;
    border-right: 1px solid rgb(60, 60, 160);
  }

  .time,
  .duration {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 60px;
    font-size: 13px;
    color: white;
  }

  .topBar {
    display: flex;
    justify-content: flex-start;
    height: 70px;
    width: 100%;
    gap: 15px;

    .imgContainer {
      position: relative;
      overflow: hidden;
      height: 100%;
      z-index: 5;

      img {
        border-radius: 5px;
        height: 100%;
      }
    }

    .infoContainer {
      display: flex;
      flex-direction: column;
      z-index: 5;
      color: white;
      font-weight: 500;

      & > div {
        flex: 1;
        display: flex;
        align-items: center;

        &:first-child {
          font-size: 25px;
        }
      }
    }
  }

  .controls {
    display: flex;
    justify-content: center;
    z-index: 5;
    width: 60px;

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;

      &:last-child {
        justify-content: flex-end;
      }
    }

    .playPauseButton {
      border-radius: 50%;
      min-width: 40px;
      width: 40px;
      height: 40px;
      border: none !important;
      background-color: #3c3c3c85;
    }

    .volumeControl {
      display: flex;
      align-items: center;
      width: 120px;
      gap: 5px;

      button {
        svg {
          width: 20px;
          fill: white;
        }
      }
    }
  }
}
