.codeInput {
  text-align: center;
  margin: 15px 6px;
  width: 450px;
  max-width: 100%;
  .character,
  .characterInvalid {
    text-align: center;
    font-size: 18px;
    margin: 0 10px;
    border-radius: 10px;
    border: 1px solid #eaeaf1;
    color: var(--primary-text-color);

    background-color: var(--place-holder-color);
  }
  .characterInvalid {
    box-shadow: 0 0 10px 1px rgba(236, 28, 28, 0.76);
  }
  .characterInactive {
    background-color: var(--place-holder-color);
  }
  .characterInvalid.characterSelected {
    outline: 2px solid rgba(236, 28, 28, 0.76) !important;
  }
  .characterSelected {
    outline: 2px solid var(--primary-color);
    position: relative;
  }
  .characterSelected:not(.characterFilled)::after {
    content: '';
    width: 1px;
    height: 25px;
    background-color: var(--place-holder-color);
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: blink 1s infinite step-end;
  }

  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
}

@media only screen and (max-width: 960px) {
  .codeInput {
    margin: 0;
    min-width: 100%;
    .character {
      text-align: center;
      margin: 0 6px;
      font-size: 16px;
    }
  }
}
