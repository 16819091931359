@import '../../style/colors';

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
}

.cropperContainer {
  width: calc(100% - 80px);
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $white;
  padding: 20px;
  border-radius: 8px;
}

.cropper {
  width: 400px;
  height: 400px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sliderContainer {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.slider {
  margin-left: 16px;
}

.btnGroup {
  display: flex;
  gap: 10px;
}

.button {
  width: 168px;
  height: 48px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 18px;
  background-color: $primary;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.saveCrop {
  background-color: $primary;
  color: $white;
  flex: 1;
  font-weight: 500;
}

.cancel {
  background-color: $white;
  border: 1px solid #cacaca;
  color: black;
  flex: 1;
  font-weight: 500;
}
