@import '../../style/colors';
@import '../../style/mixins.scss';

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-bottom: 5px;
  height: 0;
  opacity: 0;
  transition: 0.15s ease-out;

  &.active {
    height: 40px;
    opacity: 1;
  }
}

.moreTitle {
  font-size: 18px;
  font-weight: 600;
  display: flex;
  color: var(--primary-text-color);
  gap: 10px;
}

button.sortByButton {
  background-color: white;
  border-color: #e5e5e5;
  border-radius: 10px;
  color: #a7a7a7;
  float: right;
  padding: 10px 20px;
  margin-right: var(--space-sides);
  margin-bottom: 30px;
  display: none;
  @include respond(tab-port) {
    display: none;
  }
}

.responsiveContainer {
  transition: height 2s ease-out;
  padding-bottom: 80px;
  padding-left: var(--space-sides);
  padding-right: var(--space-sides);
  flex: 1;
  &.noPadding {
    padding: 0;
    overflow: hidden;
  }

  & > div {
    gap: calc(12px + 20 * (100vw - 600px) / 1000) !important;
  }
}

.loadingBottom {
  display: flex;
  min-height: 150px;
  justify-content: center;
  align-items: flex-start;

  & > div {
    display: flex;
    gap: 15px;
    align-items: center;

    span {
      font-size: 14px;
    }
  }
}

.button {
  width: 125px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.2px;
  box-shadow: none;
  border-radius: 12px;
  box-sizing: border-box;
  cursor: pointer;
  text-decoration: none;
  margin-top: 24px;
  margin-bottom: 24px;
  text-transform: capitalize;

  &:hover {
    box-shadow: 0 0 10px rgba(127, 86, 217, 0.5);
  }

  &:not(:last-child) {
    margin-right: 15px;
  }
  color: $white;
  background-color: $primary;
}
