.root {
  max-width: 400px;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 10px;
  align-items: center;

  &.full {
    max-width: none !important;
    grid-template-columns: 1fr;
  }

  .bioText {
    margin: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
    color: var(--primary-text-color) !important;
    &.short {
      max-height: 25px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.full {
      max-height: 200px;
      white-space: pre-wrap;
      overflow-y: scroll;
      scrollbar-color: var(--background-color) var(--background-color);
    }
  }

  .seeMore {
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
    font-weight: 500;
    min-width: fit-content;
    text-align: left;
    display: inline-block;
    color: var(--primary-color);
    &:hover {
      color: #767676;
    }
  }
}
