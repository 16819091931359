.tagContainer {
  margin-top: 10px 0;
}
.tag {
  margin-right: 10px !important;
  margin-bottom: 10px !important;
  overflow: hidden;
  background-color: var(--primary-color) !important;
  color: var(--white-color) !important;
  font-weight: 700;
}
