@font-face {
  font-family: 'NeueMontreal';
  src: url(./assets/fonts/NeueMontreal.woff2) format('woff2');
  font-weight: normal;
  font-style: normal;
}

:root {
  --space-sides: calc(30 * (100vw - 600px) / 600);
  --space-header: calc(15px + 5 * (100vw - 600px) / 600);
  --background-color: #ffffff;
  --primary-color: #06e060;
  --darker-primary-color: #1e8449;
  --lighter-primary-color: #d4f5e2;
  --box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  --header-mobile-color: #ffffff;
  --place-holder-color: #ffffff;
  --secondary-background-color: #f3f3f3;
  --third-background-color: #f3f3f3;
  --button-background-color: #f3f3f3;
  --primary-text-color: #121223;
  --secondary-text-color: #200344;
  --primary-button-text-color: #52d789;
  --secondary-button-text-color: #a7a7a7;
  --border-color: #d3d3d3;
  --secondary-border-color: #ccc3c380;
  --light-border-color: #d3d3d3;
  --white-color: #ffffff;
  --border-radius: 10px;
  --linear-background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(124, 124, 124, 1) 95%
  );
}

[data-theme='dark'] {
  --background-color: #191919f2;
  --secondary-background-color: #303030;
  --third-background-color: #404040;
  --place-holder-color: #404040;
  --header-mobile-color: #404040;
  --box-shadow: 0 0 4px 0 #ffffff;

  --primary-text-color: #ffffff;
  --secondary-text-color: #ccc3c3;
  --secondary-button-text-color: #ffffff;
  --border-color: #ccc3c3;
  --light-border-color: #efefef;
  --primary-button-text-color: #ffffff;

  // --linear-background: linear-gradient(
  //   to bottom,
  //   rgba(0, 0, 0, 0) 0%,
  //   rgba(0, 0, 0, 1) 95%
  // );
}

.css-gr2aqs {
  background-color: var(--primary-color) !important;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  overflow: auto;
  min-width: 320px;
  background-color: var(--background-color) !important;
  * {
    -webkit-font-smoothing: antialiased;
  }
}

.slick-slide {
  outline: none;
  width: unset !important;
  /* width: 360px !important; */
}

body * {
  font-family: 'neue-montreal', 'NeueMontreal', sans-serif !important;
  -webkit-tap-highlight-color: transparent;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.MuiPagination-root > .MuiPagination-ul > li > .MuiPaginationItem-root {
  font-weight: 700;
}

.MuiPagination-root > .MuiPagination-ul {
  justify-content: center;
}

.user-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--primary-text-color);
}

.user-avatar > div:nth-child(1):nth-child(1) {
  display: flex;
}

.user-avatar > div:nth-child(2) {
  display: grid;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-avatar > div:nth-child(2) div:nth-child(1) {
  font-weight: 700;
  line-height: 1rem;
  display: flex;
  justify-content: flex-start;
}

.user-avatar > div:nth-child(2) div:nth-child(2) {
  font-weight: 300;
  color: var(--secondary-text-color);
  line-height: 1rem;
  display: flex;
}

button.video-react-button.video-react-big-play-button.video-react-big-play-button-left {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border-color: transparent;

  &::before {
    position: relative;
    top: 1px;
    height: unset;
  }
}

.video-react {
  .video-react-poster {
    right: auto !important;
    left: auto !important;
  }
  .video-react-video {
    position: initial !important;
  }
  .video-react-time-control {
    display: flex;
    align-items: center;
  }
}
