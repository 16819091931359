@import '../../../style/colors';
@import '../../../style/mixins.scss';

.main {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.content {
  width: 100%;
  object-fit: cover;
  margin: 60px 0;
  & * {
    font-weight: normal;
    transition: 200ms ease;
  }

  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--primary-text-color);
    h1 {
      text-align: center;
      font-weight: 600;
      margin: 0;
      font-size: 55px;
      line-height: 55px;
      @include respond(tab-land) {
        font-size: 55px;
        line-height: 55px;
      }
      @include respond(tab-port) {
        font-size: 30px;
        line-height: 30px;
      }
    }
  }

  .subtitle1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1.2rem;
    h6 {
      text-align: center;
      color: #999999;
      margin: 0;
      font-size: 25px;
    }
    @include respond(tab-land) {
      font-size: 25px;
      line-height: 25px;
    }
    @include respond(tab-port) {
      margin-top: 20px;
      line-height: 20px;

      h6 {
        font-size: 20px;
      }
    }
  }
}
