.root {
  .modal {
    padding: 20px;

    .content {
      min-height: 60px;
      padding-top: 10px;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 5px;
    }

    .options {
      padding: 10px;
    }
  }

  .helperText {
    display: flex;
    justify-content: flex-end;
  }

  @media only screen and (max-width: 600px) {
    .modal {
      padding: 0 !important;
    }
  }
}
