.container {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  color: var(--primary-text-color);

  .title {
    font-weight: 600;
    font-size: 26px;

    .subTitle {
      font-size: 14px;
      font-weight: 400;
      max-width: 350px;
      color: var(--secondary-text-color);
    }
  }

  .itemsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .items {
      grid-row: 1;
      display: flex;
      flex: 1 1;
      flex-direction: column;
      gap: 20px;
      padding: 20px;
      border: 1px solid var(--border-color);
      border-radius: 10px;
      font-weight: 500;
      max-width: 450px;
      min-width: 330px;

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        svg {
          color: var(--primary-text-color);
        }
      }

      .details {
        display: grid;
        grid-template-columns: minmax(180px, 1.5fr) minmax(100px, 1fr);

        .info {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .siz {
            display: flex;
            align-items: baseline;
            gap: 5px;

            div:first-child {
              font-weight: 600;
              font-size: 36px;
            }
            div:nth-child(2) {
              font-weight: 400;
              font-size: 24px;
              color: lightgray;
            }
          }

          .secondLine {
            display: flex;
            align-items: center;
            gap: 8px;

            .gain {
              color: green !important;
              font-weight: 600;
            }

            .loss {
              color: #b42318 !important;
              font-weight: 600;
            }

            div:last-child {
              font-size: 15px;
              color: rgb(191, 191, 191);
            }
          }
        }
      }
    }
  }

  .accumulatedContainer {
    display: flex;
    min-height: 400px;
    padding: 10px;
    overflow: hidden;
  }

  @media only screen and (max-width: 1200px) {
    .itemsContainer {
      .items {
        grid-row: 2;

        &:nth-child(3),
        &:nth-child(4) {
          grid-row: 3;
        }
      }

      .earning {
        grid-row: 1;
        grid-column: 1 / 3;
      }
    }
  }

  .nameContainer {
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--secondary-text-color);
    font-weight: 600;
    padding-left: 10px;
    height: 100%;
    text-decoration: none;
    .avatarContainer {
      border-radius: 5px;
      overflow: hidden;
      object-fit: contain;

      .avatar {
        display: flex;
        width: 100%;
        height: 100%;
        max-height: 40px;
        max-width: 40px;
      }
    }
  }
}
